import $formatNumber from "./../../mixins/formatNumber";
import { ASSETS_URL } from "./../../services/base";
import withdrawalService from "./../../services/withdrawalService";
import Num2persian from "num2persian";

export default {
    name: "wallet-list",
    components: {},
    props: ["data", "headers"],
    mixins: [$formatNumber],
    data() {
        return {
            baseURL: ASSETS_URL,
            loading: false,
            value: "",
            shaba: "",
            name: "",
        };
    },
    computed: {
        toPersian() {
            if (this.value) {
                parseFloat(this.value);
                // eslint-disable-next-line no-useless-escape
                let toValue = this.value.replace(/\,/g, "");
                return Num2persian((toValue / 10).toFixed(0));
            } else {
                return 0;
            }
        },
        currencies() {
            return this.$store.state.currencies;
        },
    },
    mounted() {},
    methods: {
        numberFormatFunc(amount) {
            if (
                amount === "" ||
                amount == "" ||
                amount == null ||
                this.value === "" ||
                this.value == "" ||
                this.value == null
            ) {
                this.value = 0;
                amount = 0;
            }
            // eslint-disable-next-line no-useless-escape
            let mainValue = amount.replace(/\,/g, "");
            if (!mainValue.includes(".")) {
                this.value = this.$formatNumber(parseFloat(mainValue));
            }
        },
        submit(e) {
            e.preventDefault();
            this.loading = true;
            withdrawalService
                // eslint-disable-next-line no-useless-escape
                .getToken(1, this.value.replace(/\,/g, ""))
                .then((response) => {
                    withdrawalService
                        .withdrawal(
                            response.data.token,
                            // eslint-disable-next-line no-useless-escape
                            this.value.replace(/\,/g, ""),
                            this.shaba,
                            this.name
                        )
                        .then(() => {
                            this.$refs["modal-1"].hide();
                            this.value = 0;
                            this.shaba = "";
                            this.name = "";
                            this.$emit("updateData");
                            this.$bvToast.toast(
                                this.$i18n.t("panel.merchant.dashboard.wallet.successfullTransaction"),
                                {
                                    title: this.$i18n.t("toast.successTitle"),
                                    noCloseButton: true,
                                    variant: "custom",
                                    bodyClass:
                                        "successFontColor rtl text-right font-weight-bold py-3",
                                    headerClass:
                                        "successBackgroundColor successFontColor  rtl text-right",
                                    solid: false,
                                }
                            );
                        })
                        .catch((error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.$i18n.t("toast.errorTitle"),
                                noCloseButton: true,
                                variant: "custom",
                                bodyClass:
                                    "dangerBackgroundColor dangerFontColor rtl text-right",
                                headerClass:
                                    "dangerBackgroundColor dangerFontColor  rtl text-right",
                                solid: false,
                            });
                        })
                        .finally(() => (this.loading = false));
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        allWalletValue() {
            this.value = this.data[0].balance;
        },
    },
};
